.table-content-editing.invalid-input {
    z-index: 1 !important;
    overflow: visible;
}
.table-content-editing.invalid-input .rs-table-cell-content {
    overflow: visible;
}
.table-content-editing.invalid-input input {
    border: solid red 1px;
    outline: red;
}