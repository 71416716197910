

/* Navbar */

.nav-container {
  min-height: 40px;
  align-items: center;
  flex: 0 0 0;
  padding: 0 5px;
  background-color: var(--primary-light);
  border-left: solid var(--border-light) 1px;
}
.nav-img {
  background-color: white;
  border-radius: 2px;
  padding: 3px 10px;
}
.nav-img img {
  height: 15px;
  width: 65.1px;
  position: relative;
  top: 0px;
}
.nav-brand {
  font-size: 16px;
  color: var(--text-dark);
  margin-left: 35px;
  margin-right: 35px;
  flex-shrink: 0;
}
.nav-links {
  flex-shrink: 0;
}
.nav-links > .nav-link {
  padding: 5px 10px;
  margin: 0 2px;
  transition: color 500ms ease-in-out;
  transition: border 500ms ease-in-out;
  color: var(--text-dark);
  border: solid var(--primary-light) 1px;
  text-decoration: none;
}
.nav-links > .nav-link:not(.active):hover {
  color: var(--secondary-light);
  border-left: solid var(--secondary-light) 1px;
}
.nav-links > .nav-link.active {
  color: var(--secondary);
  border-left: solid var(--secondary) 1px;
}
.nav-auth {
  flex-shrink: 0;
  color: var(--text-dark)
}
.nav-auth > .greeting {
  margin-right: 5px;
}
.nav-auth > .logout {
  padding: 4px 6px;
  border-radius: 2px;
  color: var(--text-light);
  background-color: var(--secondary-dark);
}
.nav-auth > .logout:hover {
  text-decoration: none;
  border-radius: 4px;
  color: var(--primary);
  background-color: var(--secondary-light);
}

.flex-fixed {
  flex-shrink: 0;
  flex-grow: 0;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}


.fa-icon.clickable {
  cursor: pointer;
}
.fa-spin-hover:hover {
  animation: fa-spin 2s infinite linear;
  cursor: pointer;
  color: var(--secondary-light);
}
.preferences-icon {
  color: var(--text-dark);
}