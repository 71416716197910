.mantine-Switch-root > input {
    cursor: pointer;
}

.flow-horizontal.align-bl {
    align-items: baseline;
}
.flow-horizontal.align-ct {
    align-items: center;
}








.spin {
    animation: spin infinite 5s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}