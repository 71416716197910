.chart-option-btn {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
    border-radius: 2px;
    flex-shrink: 0;
    border: none;
    cursor: pointer;
    padding: 0 15px 0 15px;

    font-size: 12px;
    font-weight: bold;
}

.chart-option-btn:focus {
    outline: none;
}

.chart-option-btn.primary {
    background-color: var(--primary);
    color: var(--light);
}
.chart-option-btn.primary:hover {
    color: lightgrey;   
}
.chart-option-btn.secondary {
    background-color: var(--secondary);
    color: var(--light);
}
.chart-option-btn.secondary:hover {
    color: lightgrey;   
}

.chart-option-btn.active {
    background-color: var(--primary);
    color: var(--light);
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}