.option {
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding: 4px;
    border-radius: 2px;
    border: solid 1px var(--faint-light);
}
.option .label {
    white-space: nowrap;
    margin-right: 4px;
}