.interval-sidebar {
    width: 250px;
}
.interval-options-menu {
    overflow-y: auto;
    
}
.interval-option-panel {
    border-left: solid 5px;
    background-color: whitesmoke;
    padding: 4px;
    border-radius: 2px;
}
.interval-option-panel:hover {
    cursor: pointer;
    background-color: var(--secondary-light)
}
.interval-option-panel.active {
    color: black;
    background-color: var(--secondary-light);
}
.interval-option-panel.p3 {
    border-color: red;
}
.interval-option-panel.p2 {
    border-color: orange;
}
.interval-option-panel.p1 {
    border-color: green;
}

.panel-box-highlighter {
    padding: 2px;
    border-radius: 2px;
}
.violation-signal {
    color: white;
    margin-right: 4px;
}
.violation-signal.ads {
    background-color: #dd816f;
}
.violation-signal.agc {
    background-color: #e53f7c;
}
.violation-signal.mixed {
    background-color: #67c747;
}

.availability-box {
    background-color: red;
    flex-shrink: 0;
}