.floater-button {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
.floater-button > div:first-child {
    transition: background-color 0.2s;
}

.floater-button.add-record > div:first-child {
    background-color: #99d066;
}
.floater-button.add-record > div:first-child:hover {
    background-color: #689f38;
}
.floater-button.delete-record > div:first-child {
    background-color: rgb(247, 112, 112);
}
.floater-button.delete-record > div:first-child:hover {
    background-color: rgb(201, 76, 76);
}