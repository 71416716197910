.help-panel {
    background-color: var(--primary);
    color: var(--text-dark);

    padding: 15px 11px;
    min-width: 300px;
    max-width: 300px;
    
    border-top: solid var(--border-light) 1px;

    transition: min-width 0.3s ease-in-out, max-width 0.3s ease-in-out;
}
.help-panel.collapsed {
    background-color: var(--secondary-dark);
    color: var(--text-light);

    min-width: 40px;
    max-width: 40px;

    cursor: pointer;
}
.help-panel.collapsed:hover {
    color: var(--secondary-light);
}
.help-panel .help-item {
    color: var(--text-dark);
    
    font-weight: bold;
    flex-shrink: 1;
    flex-grow: 1;
    font-size: 16px;
}

.help-panel span svg {
    cursor: pointer;
}

.help-panel.collapsed > .prompt {
    margin-top: 20px;
    transform: rotate(90deg);
    white-space: nowrap;
}