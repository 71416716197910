.contract-cp-instance > div:not(:last-child) {
    margin-bottom: 10px;
}

.contract-metadata-section {
    justify-content: space-around;
    flex-wrap: wrap;
}
.contract-metadata-section {
    flex-shrink: 0;
    margin-right: 20px;
}
.contract-metadata-section > *:not(:first-child) {
    padding-left: 12px;
}
.contract-metadata-section > *:not(:last-child) {
    margin-bottom: 20px;
    padding-right: 12px;
}
.vertical-children-spacing > *:not(:last-child) {
    margin-bottom: 20px;
}
.horizontal-children-spacing > *:not(:last-child) {
    margin-right: 20px;
}