.display-mode-selector {
    background-color: var(--primary);
    color: var(--text-dark);
    border-bottom: solid var(--border-light) 1px;
    height: 50px;
    padding: 10px;
    align-items: center;
}

.display-mode-selector .prop {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 4px;
}
.display-mode-selector .prop.mode-prompt {
    width: 100px;
}
.display-mode-selector .prop.mode {
    width: 60px;
    cursor: pointer;
}
.display-mode-selector .mode:hover { 
    cursor: pointer;
}
.display-mode-selector .mode.active {
    background-color: var(--secondary);
    border-radius: 2px;
    color: var(--text-light);
}