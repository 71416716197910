.login-bg-image {
    overflow: auto;
}

.login-modal {
    width: 30%;
    height: 70%;
    min-height: 450px;
    margin: auto;
    justify-content: center;
    align-items: center;
    overflow: auto;

    padding: 30px 15px 20px 15px;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
    
    border-radius: 40px;
    background-color: #FFFFFFF2;
    color: #05425B;
}

.logo-image-container {
    overflow: hidden;
    height: 130px;
    max-height: 300px;
    width: 250px;
    margin-top: -50px;
    position: relative;
    flex-shrink: 0;
}

.logo-image {    
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: -35px;
}


.login-greeting {
    margin-bottom: 35px;
}
.login-btn {
    background-color: #05425B;
    border-radius: 50px;
    margin-top: 75px;

    flex-shrink: 0;
}
.login-btn:hover {
    background-color: #08597a;
}
.login-error-alert {
    margin-bottom: 10px;
    flex-shrink: 0;
}
.login-username, .login-password {
    width: 70%;
}

/* Default: Large devices such as laptops, computers (greater than 1024px) *

/* Medium devices such as tablets (1024px or lesser) */
@media only screen and (max-height: 800px), screen and (max-width: 1280px) {
    .login-btn {
        margin-top: 40px;
    }
    .login-greeting {
        margin-bottom: 15px;
    }
}