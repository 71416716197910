.app-nav {
    flex-shrink: 0;
    height: 45px;
    background-color: #222C37;
    border-bottom: solid black 1px;
    align-items: center;
}

.app-nav .logo {
    position: relative;
    top: 31px;
    left: 0px;
    aspect-ratio: 1 / 1;
    height: 140px;
    width: auto;
    margin-left: 20px;
}

.app-nav .palantir-logo {
    aspect-ratio: 1 / 1;
    height: 25px;
    margin-left: 20px;
}

.app-nav > .align-right {
    margin-left: auto;
    margin-right: 15px;
    flex-shrink: 0;
}

.app-nav .user-avatar {
    position: relative;
    top: 3px;
    cursor: pointer;
}