.crud-table .rs-table {
    font-size: 14px;
}


.table-content-editing .rs-input, .table-content-editing .rs-btn, .table-content-editing .mantine-MultiSelect-root {
    position: relative;
    top: -8px;
    border-radius: 0;
    background-color: var(--secondary-faint);
}
.table-content-editing .rs-picker-select, .table-content-editing .rs-picker-date {
    width: 100%;
}

.crud-table .options-icon {
    cursor: pointer;
}
.crud-table .action-cell button {
    padding: 0px;
}

.crud-table .add-item-prompt {
    align-items: center;
    flex-shrink: 0;
    font-size: 12px;
}
.crud-table .add-item-prompt:hover {
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
    color: var(--secondary-light);
}
.crud-table .add-item-prompt:hover svg {
    animation: fa-spin 2s infinite linear;
    cursor: pointer;
    color: var(--secondary-light);
  }


.external-edit-cell .rs-table-cell-content {
    display: flex;
    flex-wrap: row;
    justify-content: space-between;
}

.external-edit-cell span {
    overflow-x: auto;
    text-overflow: show;
}
.external-edit-cell span::-webkit-scrollbar {
    display: none;
}

.crud-table .rs-table-header-row-wrapper .rs-table-cell-content .fa-icon {
    cursor: pointer; 
}

.crud-table .fa-icon {
    color: lightgrey;
}
.crud-table .rs-table-cell-header .label:hover {
    /*color: var(--secondary)*/
}
.crud-table .fa-icon.active {
    color: var(--tertiary);
}
.crud-table .fa-icon:not(.sorted):hover {
    color: var(--secondary-light)
}