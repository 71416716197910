.field-group .header {
    font-size: 18px;
    margin: 0px 0 25px 0px;
    flex-shrink: 0;
    flex-grow: 0;
}
.field-group .header:before {
    content: "";
    display: block;
    width: 25%;
    margin-top: 4px;
    border-top: solid var(--secondary-light) 2px;
}
.field-group .header:after {
    content: "";
    display: block;
    width: 75%;
    margin-top: 4px;
    border-top: solid var(--secondary-light) 2px;
}

#plant-identification > .MuiTextField-root {
    margin: 0 15px 0 0px;
}
#plant-entities > .MuiAutocomplete-root, #plant-entities > .MuiFormControl-root {
    margin: 15px 0 0 0;
}
#plant-metadata > .MuiFormControl-root {
    margin: 15px 0 0 0;
}
#plant-metadata > .header:not(:first-child) {
    margin-top: 30px;
}
#plant-location > .MuiFormControl-root {
    margin: 0 20px 20px 0;
}

.view-panel > .header {
    background-color: var(--secondary);
    padding: 6px 12px;
    color: var(--text-light);
    border-radius: 6px;
}

.form-instance {
    margin-bottom: 20px;
    border: solid 1px lightgrey;
    background-color: rgb(253, 253, 253);
    padding: 20px 20px 25px 20px;
    border-radius: 2px 18px 18px 2px;
}

.form-sub-instance {
    margin-bottom: 20px;
    border: solid 1px lightgrey;
    background-color: rgb(255, 255, 255);
    padding: 10px 10px 15px 10px;
    border-radius: 2px 18px 18px 2px;
}

.project-equipment.form-instance {
    margin-bottom: 40px;
    min-width: 450px;
}