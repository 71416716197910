.palantir-info-link {
    color: var(--primary);
    text-decoration: underline;
}
.palantir-info-link:hover {
    color: var(--secondary);
}

.palantir-helpnote {
    font-size: 14px;
}
.palantir-helpnote hr {
    margin-top: 8px;
    margin-bottom: 8px;
}
.palantir-helpnote .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
}
.palantir-helpnote .header {
    font-size: 20px;
    margin-top: 18px;
    font-style: italic;
    
}
.palantir-helpnote .header-2 {
    font-size: 18px;
    margin-top: 16px;
    font-style: italic;
    border-bottom: solid #e5e5ea 1px;
}
.palantir-helpnote .footnote {
    font-size: 11px;
    font-style: italic;
}
.palantir-helpnote .section {
    margin: 10px 0;
}
.palantir-helpnote .code {
    font-size: 12px;
    background-color: var(--light);
    padding: 6px;
    border-radius: 2px;
}
.palantir-helpnote .table {
    border-collapse: separate;
    font-size: 12px;
}
.palantir-helpnote .table .highlighted {
    background-color: var(--secondary-trans-6);
}
.palantir-helpnote .table td, .palantir-helpnote .table th {
    border: solid #999 1px;
    padding: 6px;
    text-align: left;
}

.palantir-helpnote.sm {
    font-size: 12px;
}
.palantir-helpnote.sm hr {
    margin-top: 4px;
    margin-bottom: 4px;
}
.palantir-helpnote.sm .title {
    font-size: 16px;
    margin-bottom: 8px
}
.palantir-helpnote.sm .header {
    font-size: 16px;
    margin-top: 14px;
}
.palantir-helpnote-sm .header-2 {
    font-size: 14px;
}

.help-prompt:hover {
    color: var(--tertiary);
}