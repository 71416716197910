.market-table {  
  border-collapse: separate;
  border-spacing: 4px;
}

.market-table tbody {
  overflow: hidden;
}
  
.market-table th, .market-table td {
  padding: 4px 10px 4px 10px;
  text-align: right;
}

.market-table th {
  position: sticky;
  top: -15px;
}

.market-table th.historical {
  background-color: var(--primary);
}
.market-table th.current {
  background-color: var(--tertiary);
}
.market-table th.forecast {
  background-color: var(--secondary);
}

.market-table tr:not(.header):hover {
  background-color: var(--primary);
}

.market-table th {
  font-weight: normal;
}
.market-table .section-header {
  background-color: var(--primary);
}