.event-table .rs-table-header-row-wrapper > .ts-table-row {
    position: fixed;
}

.events-table :not(.rs-table-row-header).rs-table-row .rs-table-cell:hover {
    background-color: lightgray !important;
}

.events-table .event-status-label {
    border-radius: 6px;
    padding: 3px;
    width: 60px;
    text-align: center;
}
.events-table .event-status-label.closed {
    background-color: lightblue;
    color: white;
}
.events-table .event-status-label.open {
    background-color: orange;
    color: white;
}

.event-meta-table.minimal-table {
    table-layout: fixed;
    width: 99%;
    border-collapse: collapse;
}
.event-meta-table.minimal-table td {
    border: solid lightgrey 1px;
    padding: 2px;
}
.event-meta-table.minimal-table td:hover {
    background-color: rgb(242, 242, 242);
}
.minimal-table-header {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 6px;
}

.events-table .fa-icon {
    color: lightgrey;
}
.events-table .fa-icon.active {
    color: var(--primary);
}
.events-table .fa-icon:not(.sorted):hover {
    color: var(--secondary)
}