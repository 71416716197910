.user-menu-sidebar {
    flex-shrink: 0;
    flex-grow: 0;

    background-color: #222C37;

    transition: min-width 0.5s ease-in-out, max-width 0.5s ease-in-out;
}

.user-menu-sidebar:not(.collapsed) {
    min-width: 200px;
    max-width: 200px;
}
.user-menu-sidebar.collapsed {
    min-width: 46px;
    max-width: 46px;
}

.user-menu-sidebar .action-cell {
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding-left: 8px;
}

.user-menu-sidebar .pltr-link:hover {
    text-decoration: none;
}

.user-menu-sidebar .action-cell:hover {
    background-color: #1b232b;
}
.user-menu-sidebar .action-cell.active {
    background-color: black;
}
.user-menu-sidebar .action-cell .nav-tab {
    background-color: rgba(50,61,71,0.8);
    border: none;
}

.user-menu-sidebar .action-cell .fa-icon {
    color: rgb(193, 193, 193);
}
.user-menu-sidebar .action-cell .fa-icon:hover {
    cursor: pointer;
}

.user-menu-sidebar .expanded-content {
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;
}
.user-menu-sidebar .expanded-content .text {
    color: rgb(193, 193, 193);
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}