.menu-item {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu-item > .item-main-content {
    display: flex;
    align-items: center;
    overflow: hidden;
}
.menu-item .label {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}
.menu-item svg {
    cursor: pointer;
}

.menu-item.leaf-node.standard {
    margin-left: 19px;
}




.menu-enter {
    max-height: 0px;
    transition: max-height 300ms ease;
    overflow: hidden;
}
.menu-enter.menu-enter-active {
    max-height: 1000px;
}
.menu-leave {
    max-height: 1000px;
    transition: max-height 300ms ease; 
}
.menu-leave.menu-leave-active {
    overflow: hidden;
    max-height: 0px; 
}