.series-table {
    table-layout: fixed;
    text-align: left;
    width: 100%;
    font-size: 14px;

    /*font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;*/
}

.snapshot-container .snapshot-row {
    border-bottom: solid lightgrey 1px;
    border-top: solid lightgrey 1px;
    padding: 3px;
}

.series-table tr {
    border-bottom: solid rgb(227, 227, 227) 1px;
    border-top: solid rgb(227, 227, 227) 1px;
    padding: 6px 0;
}
.series-table tr:hover {
    cursor: pointer;
}

.series-table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rs-tooltip {
    color: red;
}