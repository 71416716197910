.project-equipment-dates > div {
    margin-bottom: 20px;
}

.project-equipment-dates {
    padding-right: 20px;
}

.project-equipment-spec > div {
    margin-bottom: 20px;
    margin-right: 20px;
}

.project-equipment > .body {
    flex-wrap: wrap;
    justify-content: space-around;
    display: flex;
}
.project-equipment > .body > div {
    margin-bottom: 20px;
}

.project-equipment .equipment-meta-col {
    flex-shrink: 1;
    flex-grow: 1;
    width: 250px;
    margin-right: 50px;
}