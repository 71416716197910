.table-display-header {
    margin-bottom: 10px;

    align-items: baseline;
}
.table-display-header > .title {
    font-size: 18px;
}
.table-display-header > .title > .item {
    display: inline-flex;
    align-items: baseline;
}
.table-display-header > .title > .item .fa-icon {
    font-size: 12px; 
    margin-right: 10px;
}
.table-display-header .fa-icon:hover {
    cursor: pointer;
    color: var(--primary);
}
