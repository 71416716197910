.options-icon:hover {
    color: var(--secondary);
}

.option-cell-menu {
    padding: 0px;
    min-width: 150px;
}
.option-cell-menu .dropdown-container {
    padding: 2px;
}

.option-cell-menu .rs-dropdown-menu {
    padding: 0;
}

.option-cell-menu .rs-dropdown-menu .rs-dropdown-item-content {
    padding: 4px 12px;
}

.option-cell-menu .rs-dropdown-menu .rs-dropdown-item-content:hover {
    color: var(--dark);
    background-color: var(--secondary-trans-3);
}