.pltr-menu {
    background-color: white;
    transition: min-width 0.5s ease-in-out, max-width 0.5s ease-in-out;
}

.pltr-menu.sidebar:not(.collapsed):not(.custom-dimensions) {
    min-width: 225px;
    max-width: 225px;
}
.pltr-menu.sidebar.collapsed {
    min-width: 35px;
    max-width: 35px;
    /*border-right: solid var(--border-light) 1px;
    border-left: solid var(--border-light) 1px;*/
    background-color: var(--secondary-dark);
    color: var(--text-light);
}



.pltr-menu .menu-body {
    border-top: solid var(--border-light) 1px;
    border-bottom: solid var(--border-light) 1px;
}
.pltr-menu.sidebar .menu-body {
    border-color: var(--border-light);
    border-bottom: none;
}

/* Menu header styles */

.menu-header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    
}
.pltr-menu.add-peripherals .menu-header {
    border-bottom: solid var(--border-light) 1px;
}
.menu-header .label {
    font-size: 16px;
    margin-left: 5px;
    white-space: nowrap;
}

/* Menu peripherals */

.pltr-menu .menu-peripherals {
    align-items: center;
    flex-shrink: 0;
    padding: 8px 12px 8px 6px;
}

.pltr-menu .menu-peripherals .pltr-input {
    background-color: var(--border-light);
    color: var(--text-dark);
    border-radius: 2px;
}
.pltr-menu .menu-peripherals .pltr-input.pltr-search {
    width: 100%;
    margin: 0 0px 0 4px;
    border: solid var(--border-light) 1px;
}
.pltr-menu .menu-peripherals .pltr-input:focus, .pltr-menu .menu-peripherals .pltr-input:focus-visible {
    outline: none;
}

.pltr-menu .menu-peripherals .fa-ellipsis-v {
    cursor: pointer;
}
.pltr-popover-menu-container > div {
    padding: 2px;
}
.pltr-popover-menu-container .rs-popover-arrow {
    top: 7px !important;
    right: -5px !important;
}
.pltr-popover-menu-container .pltr-choice-dropdown .choice {
    display: inline;
    border-radius: 2px;
    padding: 4px 20px 4px 4px;
}
.pltr-popover-menu-container .pltr-choice-dropdown .choice:hover {
    cursor: pointer;
    background-color: var(--border-light);
}
.pltr-popover-menu-container .pltr-choice-dropdown .choice > svg {
    margin-right: 4px;
}

/* Menu collapsed */

.pltr-menu.collapsed .collapse-toggle {
    margin: 12px 7.5px 0 7.5px;
}
.pltr-menu .collapse-toggle:hover {
    color: var(--primary-dark);
    cursor: pointer;
}




/* Sidebar styles */

.pltr-menu.sidebar {
    background-color: var(--primary);
    color: var(--text-dark);
    
    /*border-right: solid var(--secondary-light) 1px;
    box-shadow: 10px 0px 10px -8px var(--secondary);
    z-index: 1;*/
}
.pltr-menu.sidebar .menu-header {
    padding: 8.6px 7.5px;
    /*margin: 8.6px 0;*/
}

.pltr-menu .menu-item {
    padding: 2px 0;
}

.pltr-menu .menu-item.standard .label {
    padding: 1px 5px;
    position: relative;
}
.pltr-menu .menu-item.standard .label:hover {
    color: rgb(118, 118, 118);
}
.pltr-menu .menu-item.standard.active .label {
    
}
.pltr-menu .menu-item.standard .label::after {
    content: "";
    position: absolute;
    background: var(--secondary-light);
    width: 0;
    height: 2px;
    bottom: 0px;
    left: 5px;
    transition:.5s;
}
.pltr-menu .menu-item.standard .label:hover:after {
    width: 30%;
}
.pltr-menu .menu-item.standard.active .label::after {
    width: 100%;
    background: var(--secondary)
}