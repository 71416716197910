.app-choice {
    padding: 100px;
    color: var(--light);
    background-color: var(--primary);

    font-size: 26px;
}

.app-choice:hover {
    color: var(--light);
    text-decoration: none;

	-webkit-animation: jello-horizontal 3s infinite both;
	animation: jello-horizontal 3s infinite both;
}

.application-list {
  justify-content: space-evenly;
  align-items: center;
  padding: 200px;
}
.application-list > .no-apps {
  background-color: palevioletred;
    color: white;
    padding: 10px;
    border-radius: 2px;
}

.logout:hover {
  color: var(--primary);
  cursor: pointer;
  text-decoration: underline;
}

.content > * {
  margin-bottom: 50px;
  color: white;
}


.welcome-dialog {
  position: absolute;
  left: calc(50% - 100px);
  width: 200px;
  height: 200px;
  z-index: 1000;

  border-radius: 16px;
  background-color: white;

  align-items: center;
  justify-content: center;
}
.app-select-app-container.container-one {
  background-color: #05425B;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: calc(50%+127px);
}
.app-select-app-container.container-two {
  background-color: #74D3DE;
  align-items: center;
  justify-content: center;

  position: relative;
  height: 100%;
  width: calc(50%-127px);
}
.container-two:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: -100%;
  bottom: 0;
  background-color: #74D3DE;
  transform: skewY(-80deg);
}
.content {
	max-width: 50em;
  margin: auto auto;
  position: relative;
  height: 100%;

  align-items: center;
  justify-content: center;
}

.welcome-dialog .logo-container {
  height: 100px;

}
.welcome-dialog .logo {
  position: absolute;
  left: 5px;
  bottom: 60px;
  height: 110px;
  width: auto;
}

.welcome-dialog .prompt {
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}