.pltr-simple-menu {
    min-width: 200px;
    border: solid 1px rgb(212, 212, 212);
    background-color: #FFFFFF;
    border-radius: 4px;
}
.pltr-simple-menu > .header {
    min-height: 50px;
    padding: 10px 8px;
    flex-shrink: 0;
    flex-grow: 0;
    border-bottom: solid rgb(212, 212, 212) 1px;
    box-shadow: 0 1.2px 7.2px 0 rgb(0 0 0 / 13%); /*, 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);*/
}
.pltr-simple-menu > .header > .text {
    display: flex;
    align-items: center;
}
.pltr-simple-menu .item-container:not(:last-child) {
    border-bottom: solid rgb(212, 212, 212) 1px;
}
.pltr-simple-menu .item {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin: 4px;
    padding: 4px 10px;
    border-radius: 4px;
}
.pltr-simple-menu .item:hover {
    cursor: pointer;
    background-color: rgb(245, 245, 245);
}
.pltr-simple-menu .item.active {
    color: white;
    background-color: var(--secondary);
}
.pltr-simple-menu .item > .icon {
    margin-right: 10px;
}
.pltr-simple-menu .footer {
    border-top: solid rgb(212, 212, 212) 1px;
    min-height: 10px;
}

.pltr-simple-menu .header .create-icon {
    cursor: pointer;
    color: grey;
}
.pltr-simple-menu .header .create-icon:hover {
    color: rgb(190, 190, 190);
}
.pltr-simple-menu .header .create-icon.active {
    color: var(--secondary);
}
