.section-main .section-header {
    margin-bottom: 15px;
    flex-shrink: 0;
}
.section-main .section-header.flow-horizontal {
    align-items: center;
}
.section-panel .section-header .title {
    white-space: nowrap;
    font-size: 16px;
    font-weight: bold;
    color: rgb(68, 68, 68);
}
.section-main .section-header .title {
    white-space: nowrap;
    font-size: 20px;
    font-weight: bold;
    color: rgb(68, 68, 68);
}

.section-main .section-header .help-icon {
    margin-left: 5px;
    position: relative;
    top: 2px;
    cursor: pointer;
    color: var(--dark);
}
.section-main .section-header .help-icon:hover {
    color: var(--primary);
}