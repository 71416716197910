.sidebar > .display-mode-selector {
    min-height: 50px;
    max-height: 50px;
}

.plant-menu-top-filler {
    min-height: 50px;
    border-bottom: solid var(--border-light) 1px;
    border-right: solid var(--border-light) 1px;
    background-color: var(--background-primary);
}