body {
  margin: 0;
  font-size: 11px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary: #fcfcfc;
  --primary-light: #ffffff;
  --primary-dark: #c2c2c2;

  
  --secondary: #df827f;
  --secondary-light: #e1a7a5;
  --secondary-dark: #e1635f;
  
  /*
  --secondary: #90cde7;
  --secondary-light: #36afe3;
  --secondary-dark: #5cbae2;
  */
  /*
  Greens
  #689f38;
  #99d066
  #387002
  */

  --tertiary: #ffc400;
  --tertiary-light: #fff64f;
  --tertiary-dark: #c79400;

  --text-dark: black;
  --text-light: whitesmoke;

  --background-primary: #fcfcfc;
  --background-secondary: white;
  --background-dark: #424242;

  --border-dark: #949494;
  --border-light: #eceff1;
  /*--white: white;
  --light: #f6f7f1;
  --faint-light: rgb(235, 235, 235);
  --faint-light-trans-6: #34a0a460;
  --primary: #ed901f;
  --primary-trans-6: #34a0a460;
  --primary-trans-8: #34a0a480;
  --secondary: #a1c9c9;
  --secondary-light: #B3DBDD;
  --secondary-faint: #EDF5F5;
  --secondary-trans-3: #a1c9c930;
  --secondary-trans-6: #a1c9c960;
  --accent: #db9487;
  --deep-dark: rgb(44 44 44);
  --dark: #6ec6ff;
  --dark-trans-8: rgba(68, 68, 68, 0.8);
  --dark-trans-4: rgba(68, 68, 68, 0.0);
  --light-dark: rgb(120,120,120);*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  height: 100%;
}

html *::-webkit-scrollbar-thumb {
  background-color: rgba(162,172,182,0.3); /* rgba(162,172,182,0.3) */
  border-radius: 5px;
}
html *::-webkit-scrollbar-track {
  background: rgba(170,170,170,0.2);
}

body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

/* Utility */

.flow-vertical {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}

.flow-horizontal {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  overflow: hidden;
}

.overflow-visible {
  overflow: auto;
}

.hide-overflow {
  overflow: hidden;
}

.flow-horizontal>.fill-parent {
  width: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}

.flow-vertical>.fill-parent {
  height: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}

/* General CSS Classes */

.overflow-y {
  overflow-y: auto;
}

.section-main {
  padding: 15px;
  background-color: var(--background-primary);
  border-top: solid var(--border-light) 1px;
}
.section-main.nopad {
  padding: 0px;
}
.section-panel {
  background-color: var(--background-secondary);
  padding: 15px;
  border-radius: 2px;
  border: solid var(--border-light) 1px;
}
.section-main.nopad .section-panel {
  margin: 15px;
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}