.rs-tooltip .rs-tooltip-arrow {
    opacity: 0.8;
}
.rs-tooltip .rs-tooltip-inner {
    opacity: 0.8;
    /*color: black;
    background-color: white;*/
    /*border: solid 1px rgb(230, 230, 230);*/
    box-shadow: 0 1.2px 7.2px 0 rgb(0 0 0 / 13%);
}
