.offtake-metadata-section > * {
    margin-bottom: 20px !important;
    margin-right: 20px !important;
}

.offtake-metada-sections {
    flex-wrap: wrap;
}
.offtake-metada-sections > * {
    flex-shrink: 0;
    margin-right: 20px;
}